import _ from "lodash";
import { colors, createTheme as createMuiTheme, responsiveFontSizes } from "@material-ui/core";
import { Theme as MuiTheme } from "@material-ui/core/styles/";
import { Palette as MuiPalette } from "@material-ui/core/styles/createPalette";
import { THEMES } from "src/constants";
import { softShadows, strongShadows } from "./shadows";
import typography from "./typography";

interface Palette extends MuiPalette {
  background: MuiPalette["background"] & {
    dark: string;
  };
}

export interface Theme extends MuiTheme {
  name: string;
  palette: Palette;
}

interface ThemeConfig {
  direction?: MuiTheme["direction"];
  responsiveFontSizes?: boolean;
  theme?: string;
}

const baseOptions: DeepPartial<Theme> = {
  direction: "ltr",
  typography,
  overrides: {
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: "hidden"
      }
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32
      }
    },
    MuiChip: {
      root: {
        backgroundColor: "rgba(0,0,0,0.075)"
      }
    }
  }
};

const themesOptions: DeepPartial<Theme>[] = [
  {
    name: THEMES.LIGHT,
    overrides: {
      MuiInputBase: {
        input: {
          "&::placeholder": {
            opacity: 1,
            color: colors.blueGrey[600]
          }
        }
      },

      MuiButton: {
        containedSecondary: {
          color: colors.common.white,
          "&:hover": {
            backgroundColor: "#FFC907"
          }
        }
      }
    },
    palette: {
      type: "light",
      background: {
        default: colors.common.white,
        dark: "#f4f6f8",
        paper: colors.common.white
      },
      action: {
        active: colors.blueGrey[600]
      },
      primary: {
        main: "#33B1F7",
        dark: "#263238"
      },
      secondary: {
        main: "#FFC107"
      },
      text: {
        primary: colors.blueGrey[900],
        secondary: colors.blueGrey[600]
      }
    },
    shadows: softShadows
  }
];

export const createTheme = (config: ThemeConfig = {}): Theme => {
  let themeOptions = themesOptions.find(t => t.name === config.theme);

  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`));
    [themeOptions] = themesOptions;
  }

  let theme = createMuiTheme(_.merge({}, baseOptions, themeOptions, { direction: config.direction }) as any);

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  theme.breakpoints.values = {
    lg: 1024,
    md: 768,
    sm: 640,
    xl: 1280,
    xs: 0
  };

  return theme as Theme;
};
type DeepPartial<T> = {
  [P in keyof T]?: DeepPartial<T[P]>;
};

export enum COLORS {
  white = "#fff",
  white_66 = "#A8ABAE",
  grey = "#2E373E",
  grey_66 = "#6B7176",
  grey_45 = "rgb(151, 155, 158)",
  grey_33 = "#E0E1E2",
  grey_15 = "#A8ABAE",
  grey_07 = "#F0F1F2",
  grey_04 = "#F9F9F9",
  green = "#03E076",
  green_66 = "rgb(87, 223, 155)",
  green_15 = "#D9FAEA",
  green_07 = "rgb(237, 253, 245)",
  yellow = "#F7CA44",
  yellow_66 = "rgb(246, 208, 128)",
  yellow_15 = "#FEF7E3",
  red = "#EB5757",
  red_66 = "rgb(237, 133, 135)",
  red_15 = "#FCE6E6",
  blue = "#33B1F7",
  blue_66 = "rgb(114, 197, 246)",
  blue_15 = "#E0F3FE",
  purple = "#A863DF",
  purple_66 = "rgb(190, 141, 226)",
  purple_15 = "#F2E8FA",
  orange = "#F7A933",
  orange_66 = "rgb(236, 153, 113)",
  orange_15 = "#FEF2E0",
  gradientEndGreen = "#1EF08B",
  gold = "#F6C043",
  black = "#2E373E",

  // For Team Colors
  lightRed = "#FA847C",
  lightOrange = "#FFA95A",
  lightYellow = "#FFDE6B",
  lightGreen = "#8EDF27",
  aqua = "#03C5E0",
  pink = "#F65ADD",
  turquoise = "#03E0D3",
  lightBlue = "#68D5FE",
  lightPink = "#ED95E4",
  lightPurple = "#AC92EC"
}

export const GRADIENTS = {
  gold: ["#F7CC45", "#F19D38"],
  silver: [COLORS.grey_15, COLORS.grey_66],
  green: [COLORS.gradientEndGreen, COLORS.green]
};

export type COLORS_BASE =
  | COLORS.green
  | COLORS.grey
  | COLORS.white
  | COLORS.yellow
  | COLORS.red
  | COLORS.blue
  | COLORS.purple
  | COLORS.orange
  | COLORS.lightBlue
  | COLORS.lightGreen
  | COLORS.lightOrange
  | COLORS.lightPink
  | COLORS.lightPurple
  | COLORS.lightRed
  | COLORS.lightYellow
  | COLORS.aqua
  | COLORS.pink
  | COLORS.turquoise
  | COLORS.gold;

export function COLOR_LIGHTEN(p: { opacity: 0.15 | 0.66; color: COLORS; defaultColor: COLORS }) {
  if (p.opacity === 0.15) {
    return COLOR_TO_LIGHTER_COLOR[p.color] || p.defaultColor;
  } else {
    return COLOR_TO_LIGHT_COLOR[p.color] || p.defaultColor;
  }
}

//Typically we want to use the pre-defiend enums. Only use this function if  you absolutely need opacity
export function COLOR_OPACITY_REDUCE(color: COLORS_BASE, opacity: number): COLORS {
  const rgb = hexToRGB(color);
  return `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, ${opacity})` as any as COLORS;
}

//Typically we want to use the pre-defiend enums. Only use this function if  you absolutely need arbitrarily granular color reduction
export function COLOR_WHITEN(color: COLORS, alpha: number): COLORS {
  const rgb = hexToRGB(color);
  const val = `rgb(${(1 - alpha) * 255 + alpha * rgb[0]}, ${(1 - alpha) * 255 + alpha * rgb[1]}, ${
    (1 - alpha) * 255 + alpha * rgb[2]
  })` as COLORS;

  return val;
}

export const COLOR_TO_LIGHT_COLOR: { [k in COLORS]?: COLORS } = {
  [COLORS.green]: COLORS.green_15,
  [COLORS.yellow]: COLORS.yellow_15,
  [COLORS.red]: COLORS.red_15,
  [COLORS.blue]: COLORS.blue_15,
  [COLORS.purple]: COLORS.purple_15
};

export const COLOR_TO_LIGHTER_COLOR: { [k in COLORS]?: COLORS } = {
  [COLORS.green]: COLORS.green_66,
  [COLORS.yellow]: COLORS.yellow_66,
  [COLORS.red]: COLORS.red_66,
  [COLORS.blue]: COLORS.blue_66,
  [COLORS.purple]: COLORS.purple_66
};

function hexToRGB(hex: string) {
  var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b;
  });

  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex) as string[];
  return [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)];
}
